import React, { useEffect, useState } from "react";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import { ReactComponent as PrototypeEnabled } from "assets/icons/multicolored/prototype-enabled.svg";
import { ReactComponent as ProductEnabled } from "assets/icons/multicolored/product-enabled.svg";
import { ReactComponent as Settings } from "assets/icons/multicolored/settings.svg";
import { ReactComponent as SettingsEnabled } from "assets/icons/multicolored/settings-enabled.svg";
import {
  Products,
  Users,
  Prototype,
  Picklist,
  User as UserIcon,
  Phone,
  Mail,
  Logout,
} from "shared/icons";
import { Popover } from "antd";
import useRedirect from "shared/hooks/useRedirect";
import Button from "../Button";
import { ButtonType } from "enums/buttonType.enum";

interface NavItem {
  icon?: React.ReactElement;
  iconEnabled?: React.ReactElement;
  url?: string;
}

interface NavItemMiddle extends NavItem {
  title: string;
}

interface NavItemRight extends NavItem {
  component?: React.ReactElement;
  iconType?: string;
}

export const UserProfile = () => {
  const [toggle, setToggle] = useState<boolean>(false);
  const { redirectToLogin } = useRedirect();
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");

  const handleLogout = () => {
    localStorage.clear();
    redirectToLogin();
  };

  useEffect(() => {
    if (!user.id) {
      handleLogout();
    }
  }, []);

  return (
    <Popover
      arrow={false}
      rootClassName="user-profile-popover__root"
      trigger={"click"}
      placement="bottom"
      overlayStyle={{ top: "12rem" }}
      onOpenChange={(e) => {
        setToggle(e);
      }}
      content={
        <div className="user-profile-popover__container">
          <div className="user-profile-popover__demographic">
            <div className="user-profile-popover__demographic--icon">
              <UserIcon />
            </div>
            <div className="user-profile-popover__demographic--text">
              <div className="user-profile-popover__name">{user?.name}</div>
              <div className="user-profile-popover__role">{user?.role}</div>
            </div>
          </div>
          <div className="user-profile-popover__contact">
            <div className="user-profile-popover__email">
              <Mail />
              {user?.email}
            </div>
            <div className="user-profile-popover__phone">
              <Phone />
              {user?.phoneNumber?.replace(
                /^(\d{3})(\d{3})(\d{4})/,
                "($1)$2-$3"
              )}
            </div>
          </div>
          <div className="user-profile-popover__actions">
            <Button
              type={ButtonType.DEFAULT}
              className="button-logout"
              onClick={handleLogout}
            >
              <Logout />
              Logout
            </Button>
          </div>
        </div>
      }
    >
      <div
        role="button"
        className={`icon--user ${toggle ? "icon--user__active" : ""}`}
      ></div>
    </Popover>
  );
};

const navItems: NavItemMiddle[] = [
  {
    title: "Prototypes",
    url: NavigationRoutes.PROTOTYPE,
    icon: (
      <div className="fix-center">
        <Prototype className="icons--prototype" />
      </div>
    ),
    iconEnabled: <PrototypeEnabled />,
  },
  {
    title: "Space Types",
    url: NavigationRoutes.SPACE_TYPES,
  },
  {
    title: "Products",
    url: NavigationRoutes.PRODUCTS,
    icon: (
      <div className="fix-center">
        <Products className="icons--products" />
      </div>
    ),
    iconEnabled: <ProductEnabled />,
  },
  {
    title: "Users",
    url: NavigationRoutes.USERS,
    icon: (
      <div className="fix-center">
        <Users className="icons--users fix-center" />
      </div>
    ),
  },
  {
    title: "Picklists",
    url: NavigationRoutes.PICKLIST,
    icon: (
      <div className="fix-center">
        <Picklist className="icons--picklists fix-center" />
      </div>
    ),
  },
];

export const navItemsRight: NavItemRight[] = [
  {
    icon: <span className="icon--settings" />,
    iconEnabled: <SettingsEnabled className="icon-settings enabled" />,
    url: NavigationRoutes.SETTINGS,
  },
];

export default navItems;
