import { CurrencyUnit } from "enums/currencyUnit.enum";
import { Attachment } from "models/Attachment/attachment.model";
import { Color } from "models/Color/color.model";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { CostPerItem } from "models/CostPerItem/costPerItem.model";
import { Finish } from "models/Finish/finish.model";
import { Material } from "models/Material/material.model";
import { Product } from "models/Product/product.model";
import { Space } from "models/Space/space.model";
import { Location } from "models/Location/location.model";
import {
  alias,
  getDefaultModelSchema,
  object,
  primitive,
  serializable,
  list,
} from "serializr";

// layer variant is a product variant which is to be mapped for a layer

export class VariantForLayer {
  @serializable
  id?: string;

  @serializable
  name?: string;

  @serializable(object(Material))
  material?: Material;

  @serializable(object(Finish))
  finish?: Finish;

  @serializable(object(CostPerItem))
  cost = new CostPerItem(CurrencyUnit.CAD);

  @serializable(object(Color))
  color = new Color();

  @serializable
  colorName?: string;

  @serializable(object(Attachment))
  image?: Attachment;

  @serializable(object(Product))
  product?: Product;

  @serializable(alias("is_caster", primitive()))
  isCaster?: string;

  @serializable(alias("light_type", primitive()))
  lightType?: string;

  @serializable(alias("color_temperature", primitive()))
  colorTemperature?: string;

  @serializable(alias("lead_time", primitive()))
  leadTime?: number;

  @serializable
  count?: number;

  @serializable(object(Location))
  location?: Location;
}

export class LayerVariant {
  @serializable
  id?: string;

  @serializable
  title = "Product name 1";

  @serializable(object(Coordinate))
  coordinate?: Coordinate;

  @serializable(alias("is_pinned", primitive()))
  isPinned?: boolean;

  @serializable(object(VariantForLayer))
  variant?: VariantForLayer;
}

export class Layer {
  constructor() {
    getDefaultModelSchema<Layer>(Layer)!.props.space = object(Space);
  }

  @serializable
  id?: string;

  @serializable
  title?: string;

  @serializable
  description?: string;

  @serializable(object(Attachment))
  diagram?: Attachment;

  @serializable(list(object(LayerVariant)))
  products?: LayerVariant[];

  space?: Space;
}
