import React, { ComponentProps, useEffect, useState } from "react";
import GroupedInput from "shared/components/GroupedInput";
import Dropdown from "shared/components/DropdownField";
import InputNumber from "shared/components/InputNumber";
import { DistanceUnit } from "enums/distanceUnit.enum";
import { useField, useFormikContext } from "formik";
import { Measurement } from "models/Measurement/measurement.model";
import { MassUnit } from "enums/massUnit.enum";
import { Height, Length, Weight, Width } from "shared/icons";
import { useConfigInput } from "shared/hooks/useConfigInput";
import { Product } from "models/Product/product.model";
import { generateDropdownOptions } from "shared/utils/generateDropdownOptions";
import { useLocation } from "context/LocationContext";
import { SelectProps } from "antd";
import { Location } from "models/Location/location.model";
import { SubCategory } from "models/SubCategory/subCategory.model";

import "./productFormContentRight.scss";
import ErrorMessage from "shared/components/Error";
import { useProductFilters } from "context/ProductFilterContext";
import { useSearchParams } from "react-router-dom";
import { Checkbox } from "shared/components/Checkbox";
import Input from "shared/components/Input";

// ${
//   touched?.measurement?.height && errors?.measurement?.height
//     ? "Height, "
//     : ""
// }${
//   touched?.measurement?.width && errors?.measurement?.width ? "Width, " : ""
// }${
//   touched?.measurement?.weight && errors?.measurement?.weight
//     ? "Weight, "
//     : ""
// }${
//   touched?.measurement?.length && errors?.measurement?.length
//     ? "Length, "
//     : ""
// }${
//   touched?.productionLocation?.country && errors?.productionLocation?.country
//     ? "Production Country, "
//     : ""
// }${
//   touched?.productionLocation?.country && errors?.productionLocation?.country
//     ? "Production Province, "
//     : ""
// }

const calculateOptionalFields = (value: any) => {
  if (!value)
    return {
      hasLength: false,
      hasWidth: false,
      hasHeight: false,
      hasWeight: false,
      hasProdCountry: false,
      hasProdState: false,
    };
  const hasLength = value?.measurement?.length?.value ? true : false;
  const hasWidth = value?.measurement?.width?.value ? true : false;
  const hasHeight = value?.measurement?.height?.value ? true : false;
  const hasWeight = value?.measurement?.weight?.value ? true : false;

  const hasProdCountry = value?.productionLocation?.country ? true : false;
  const hasProdState = value?.productionLocation?.province ? true : false;

  return {
    hasLength,
    hasWidth,
    hasHeight,
    hasWeight,
    hasProdCountry,
    hasProdState,
  };
};

interface ProductFormContentRightProps {
  onSubCategoriesUpdate?: (subCategories: SubCategory[]) => void;
  selectedCategoryId?: string;
  selectedSubCategoryId?: string;
  selectedTypeId?: string;

  notEdit?: boolean;
}
const ProductFormContentRight = ({
  onSubCategoriesUpdate,
  selectedCategoryId,
  selectedSubCategoryId,
  selectedTypeId,
  notEdit,
}: ProductFormContentRightProps) => {
  const name = "measurement";
  const { values, setValues, errors, touched } = useFormikContext<Product>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [{ value: measurement }, , { setValue: setMeasurement }] =
    useField<Measurement>(name);

  const [{ value: location = {} }, , { setValue: setLocation }] =
    useField<Location>("productionLocation");

  const { countries, loading } = useLocation();

  const handleCountryChange: SelectProps<string>["onChange"] = (countryId) =>
    setLocation({
      ...location,
      country: { id: countryId },
      province: undefined,
    });

  const provinces =
    countries?.find((country) => country?.id === location?.country?.id)
      ?.provinces || [];

  const handleCategoryUpdate = async (id: string) => {
    setValues(
      Object.assign(values, {
        category: {
          id,
        },
        subCategory: {
          id: undefined,
        },
        type: {
          id: undefined,
        },
      })
    );
  };

  const handleSubCategoryUpdate = async (id: string) => {
    const updatedValues = Object.assign(values, {
      subCategory: {
        id,
      },
      type: {
        id: undefined,
      },
    });
    setValues(updatedValues);
  };

  const {
    categoriesLoading,
    categories,
    subCategories,
    subCategoriesLoading,
    types,
    typesLoading,
    handleCategoryChange,
    handleSubCategoryChange,
  } = useConfigInput({
    onCategoryUpdate: handleCategoryUpdate,
    onSubCategoryUpdate: handleSubCategoryUpdate,
    onSubCategoriesUpdate,
    categoryId: selectedCategoryId,
    subCategoryId: selectedSubCategoryId,
  });

  const mapUnitsToOptions = (units: object = DistanceUnit) =>
    Object.values(units)?.map((unit) => ({
      label: unit,
      value: unit,
    }));

  const handleDistanceMetricUnits = (unit: string) => {
    const updatedUnit = unit as DistanceUnit;

    setMeasurement({
      ...measurement,
      length: {
        ...measurement?.length,
        unit: updatedUnit,
      },
      width: {
        ...measurement?.width,
        unit: updatedUnit,
      },
      height: {
        ...measurement?.height,
        unit: updatedUnit,
      },
    });
  };

  const selectAfterProps = {
    onChange: handleDistanceMetricUnits,
  };

  const errorText = `${
    touched?.category && errors?.category ? "Category, " : ""
  }${touched?.subCategory && errors?.subCategory ? "Sub Category, " : ""}${
    touched?.type && errors?.type ? "Type, " : ""
  }
  `.trim();

  useEffect(() => {
    values.category.id = selectedCategoryId;
    values.subCategory.id = selectedSubCategoryId;
    values.type.id = selectedTypeId;
    setValues(values);
  }, []);

  const [optionalFields, setOptionalFields] = useState(
    calculateOptionalFields(values)
  );

  const handleOptionalFieldChange = (field: string, checked: boolean) => {
    setOptionalFields({
      ...optionalFields,
      [field]: checked,
    });

    // Clear the corresponding field value when unchecked
    if (!checked) {
      switch (field) {
        case "hasLength":
          setMeasurement({
            ...measurement,
            length: undefined,
          });
          break;
        case "hasWidth":
          setMeasurement({
            ...measurement,
            width: undefined,
          });
          break;
        case "hasHeight":
          setMeasurement({
            ...measurement,
            height: undefined,
          });
          break;
        case "hasWeight":
          setMeasurement({
            ...measurement,
            weight: undefined,
          });
          break;
        case "hasProdCountry":
          setLocation({
            ...location,
            country: undefined,
            province: undefined,
          });
          break;
        case "hasProdState":
          setLocation({
            ...location,
            province: undefined,
          });
          break;
      }
    }
  };

  const groupedInputItems: ComponentProps<typeof GroupedInput>["items"] = [
    {
      label: "Category",
      value: (
        <Dropdown.Formik
          hideError
          bordered={false}
          className="tw-p-0"
          loading={categoriesLoading}
          name="category.id"
          onChange={handleCategoryChange}
          options={generateDropdownOptions("name", "id", categories)}
        />
      ),
    },
    {
      label: "Sub Category",
      value: (
        <Dropdown.Formik
          hideError
          bordered={false}
          loading={subCategoriesLoading}
          name="subCategory.id"
          onChange={(id: string) =>
            handleSubCategoryChange(id, values?.category?.id)
          }
          options={generateDropdownOptions("name", "id", subCategories)}
        />
      ),
    },
    {
      label: "Type",
      value: (
        <Dropdown.Formik
          bordered={false}
          hideError
          loading={typesLoading}
          name="type.id"
          options={generateDropdownOptions("name", "id", types)}
        />
      ),
    },
    {
      label: (
        <div className="tw-flex tw-gap-4 tw-items-center">
          <Checkbox
            name="hasLength"
            checked={optionalFields["hasLength"]}
            onChange={(e) =>
              handleOptionalFieldChange("hasLength", e.target.checked)
            }
          />
          <span className="icon-container">
            <Length className="icon-blue-20 icon--length" />
            <span>Length</span>
          </span>
        </div>
      ),
      value: (
        <InputNumber.Formik
          unitOptions={mapUnitsToOptions(DistanceUnit)}
          name={name?.concat(".length")}
          selectAfterProps={selectAfterProps}
          bordered={false}
          hideError
          disabled={!optionalFields["hasLength"]}
        />
      ),
    },
    {
      label: (
        <div className="tw-flex tw-gap-4 tw-items-center">
          <Checkbox
            name="hasWidth"
            checked={optionalFields["hasWidth"]}
            onChange={(e) =>
              handleOptionalFieldChange("hasWidth", e.target.checked)
            }
          />
          <span className="icon-container">
            <Width className="icon-blue-20 icon--width" />
            <span>Width</span>
          </span>
        </div>
      ),
      value: (
        <InputNumber.Formik
          unitOptions={mapUnitsToOptions(DistanceUnit)}
          name={name?.concat(".width")}
          selectAfterProps={selectAfterProps}
          bordered={false}
          hideError
          disabled={!optionalFields["hasWidth"]}
        />
      ),
    },
    {
      label: (
        <div className="tw-flex tw-gap-4 tw-items-center">
          <Checkbox
            name="hasHeight"
            checked={optionalFields["hasHeight"]}
            onChange={(e) =>
              handleOptionalFieldChange("hasHeight", e.target.checked)
            }
          />
          <span className="icon-container">
            <Height className="icon-blue-20 icon--height" />
            <span>Height</span>
          </span>
        </div>
      ),
      value: (
        <InputNumber.Formik
          unitOptions={mapUnitsToOptions(DistanceUnit)}
          name={name?.concat(".height")}
          selectAfterProps={selectAfterProps}
          bordered={false}
          hideError
          disabled={!optionalFields["hasHeight"]}
        />
      ),
    },
    {
      label: (
        <div className="tw-flex tw-gap-4 tw-items-center">
          <Checkbox
            name="hasWeight"
            checked={optionalFields["hasWeight"]}
            onChange={(e) =>
              handleOptionalFieldChange("hasWeight", e.target.checked)
            }
          />
          <span className="icon-container">
            <Weight className="icon-blue-20 icon--width" />
            <span>Weight</span>
          </span>
        </div>
      ),
      value: (
        <InputNumber.Formik
          unitOptions={mapUnitsToOptions(MassUnit)}
          name={name?.concat(".weight")}
          bordered={false}
          hideError
          disabled={!optionalFields["hasWeight"]}
        />
      ),
    },
    {
      label: (
        <div className="tw-flex tw-gap-4 tw-items-end">
          <Checkbox
            name="hasProdCountry"
            checked={optionalFields["hasProdCountry"]}
            onChange={(e) =>
              handleOptionalFieldChange("hasProdCountry", e.target.checked)
            }
          />
          <p className="tw-m-0">Production Country</p>
        </div>
      ),
      value: (
        <Dropdown.Formik
          allowClear
          bordered={false}
          loading
          name="productionLocation.country.id"
          onChange={handleCountryChange}
          options={generateDropdownOptions("name", "id", countries)}
          showSearch
          value={location?.country?.id}
          disabled={!optionalFields["hasProdCountry"]}
        />
      ),
    },
    {
      label: (
        <div className="tw-flex tw-gap-4 tw-items-center">
          <Checkbox
            name="hasProdState"
            checked={optionalFields["hasProdState"]}
            onChange={(e) =>
              handleOptionalFieldChange("hasProdState", e.target.checked)
            }
          />
          <p className="tw-m-0">Production Province / State</p>
        </div>
      ),
      value: (
        <Dropdown.Formik
          allowClear
          bordered={false}
          disabled={!location?.country?.id || !optionalFields["hasProdState"]}
          loading={loading}
          name="productionLocation.province.id"
          options={generateDropdownOptions("name" as any, "id", provinces)}
          showSearch
          value={location?.province?.id}
        />
      ),
    },
    {
      label: "Manufacturer",
      value: (
        <Input.Formik
          hideError
          bordered={false}
          className="input-field"
          name="manufacturer"
          placeholder="Enter manufacturer name"
        />
      ),
    },
    {
      label: "Model",
      value: (
        <Input.Formik
          hideError
          bordered={false}
          className="input-field"
          name="model"
          placeholder="Enter model number/name"
        />
      ),
    },
  ];
  return (
    <div className="product-form-content-right">
      <GroupedInput items={groupedInputItems} />
      <div className="grouped-error">
        {errorText !== "" && (
          <ErrorMessage
            message={`${errorText} ${
              errorText.includes(",") ? "are" : "is"
            } required`}
          />
        )}
      </div>
    </div>
  );
};

export default ProductFormContentRight;
