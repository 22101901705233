import React, { FC, useState } from "react";
import { Product } from "models/Product/product.model";
import { Variant } from "models/Variant/variant.model";
import { Dimensions } from "shared/icons";
import Collapse from "shared/components/Collapse";
import Dropdown from "shared/components/DropdownField";
import { MassUnit } from "enums/massUnit.enum";

import { Metric } from "models/Metric/metric.model";
import { parseNumber } from "shared/utils/parser";

import "./dimensionAndCost.scss";
import Divider from "shared/components/Divider";

interface DimensionAndCostProps {
  product: Product;
  variant: Variant;
}

const DimensionAndCost: FC<DimensionAndCostProps> = ({
  product: { measurement },
  variant: { cost },
}) => {
  const convertWeightValue = ({ value }: Metric<"mass">) =>
    Math.round(
      parseNumber(value) *
        Metric.getMassEquivalent(
          measurement?.weight?.unit as MassUnit,
          chosenMassMetric
        ) *
        100
    ) / 100;

  const [chosenMassMetric, setChosenMassMetric] = useState<MassUnit>(
    measurement?.weight?.unit || MassUnit.POUND
  );

  const dimensionParts = [];

  if (
    measurement?.length?.value &&
    parseFloat(measurement.length.value.toString()) !== 0
  ) {
    dimensionParts.push(
      `Length: ${measurement.length.value}${measurement.length.unit}`
    );
  }

  if (
    measurement?.width?.value &&
    parseFloat(measurement.width.value.toString()) !== 0
  ) {
    dimensionParts.push(
      `Width: ${measurement.width.value}${measurement.width.unit}`
    );
  }

  if (
    measurement?.height?.value &&
    parseFloat(measurement.height.value.toString()) !== 0
  ) {
    dimensionParts.push(
      `Height: ${measurement.height.value}${measurement.height.unit}`
    );
  }

  const details = [];

  // Only add dimensions if we have any non-zero values
  if (dimensionParts.length > 0) {
    details.push({
      label: "Dimensions",
      value: dimensionParts.join(", "),
    });
  }

  // Only add weight if it exists and is non-zero
  if (
    measurement?.weight?.value &&
    parseFloat(measurement.weight.value.toString()) !== 0
  ) {
    details.push({
      label: "Weight",
      value: (
        <div className="dimension-and-cost__value__dropdown">
          <span>{convertWeightValue(measurement.weight)}</span>
          <Dropdown
            allowClear={false}
            bordered={false}
            onChange={setChosenMassMetric}
            value={chosenMassMetric}
            showSearch={false}
            options={Object.values(MassUnit).map((value) => ({
              label: value,
              value,
            }))}
          />
        </div>
      ),
    });
  }

  const items = [
    {
      id: 1,
      details,
      getHeader: () => (
        <div className="collapse-icon-header">
          <Dimensions className="icon-grey-3 icon--dimensions" />
          <span>Dimensions</span>
        </div>
      ),
    },
  ];

  if (details?.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <div className="dimension-and-cost">
        {details?.length > 0 && (
          <Collapse defaultActiveKey={items?.[0]?.id} list={items}>
            {({ details }) => (
              <>
                {details?.map(({ label, value }) => (
                  <>
                    <span className="text-grey-2">{label}</span>
                    <span className="dimension-and-cost__value">{value}</span>
                  </>
                ))}
              </>
            )}
          </Collapse>
        )}
      </div>
    </>
  );
};

export default DimensionAndCost;
