import React, { FC } from "react";
import { Product } from "models/Product/product.model";
import { DefaultOptionType } from "antd/es/select";
import Collapse from "shared/components/Collapse";
import { Info } from "shared/icons";

import "./makeAndModel.scss";
import Divider from "shared/components/Divider";

interface MakeAndModelProps {
  product: Product;
}

const MakeAndModel: FC<MakeAndModelProps> = ({ product }) => {
  const details: DefaultOptionType[] = [
    {
      label: "Manufacturer",
      value: product?.manufacturer || "",
    },
    {
      label: "Model ID",
      value: product?.model || "",
    },
  ]?.filter(({ value }) => value.length > 0);

  const items = [
    {
      id: 1,
      details,
      getHeader: () => (
        <div className="collapse-icon-header">
          <Info className="icon-grey-3 icon--info" />
          <span>Make &amp; Model</span>
        </div>
      ),
    },
  ];

  if (details?.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <div className="make-and-model">
        <Collapse defaultActiveKey={items?.[0]?.id} list={items}>
          {({ details }) => (
            <>
              {details?.map(({ label, value }) => (
                <>
                  <span className="text-grey-2">{label}</span>
                  <span className="make-and-model__value">{value}</span>
                </>
              ))}
            </>
          )}
        </Collapse>
      </div>
    </>
  );
};

export default MakeAndModel;
